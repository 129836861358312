#PageUlProduct {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    align-content: flex-start;
}

#PageUlCategory {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    margin: 10px 5%;
    background-color: white;
}


#PagePayCard {
    width: 450px;
    border: 1px solid rgb(226, 233, 241);
    background-color: rgb(255, 255, 255);
    box-shadow: 10px 10px 10px 0 rgb(219, 218, 218);
    padding: 10px 0 30px 0;
    font-weight: 500;
    text-align: center;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

.FinishMarketAccordion .accordion-button::after {
    order: -1;
    margin-right: 10px;
}


input[type="radio"]:checked+label {
    color: #ea004b;
}

input[type="radio"]:checked {
    accent-color: #ea004b;
}

input[type="radio"].custom-radio:checked {
    border: 2px solid #ea004b;
    accent-color: #ea004b;
}

.btnMyOrders :hover {
    color: #ea004b;
    /* transition:5s; */
}

.btnPlass {
    display: flex;
    align-items: center;
}

.btnPlass:hover {
    background-color: #ffc6d8;
    border-radius: 50%;
}

.offcanvas-header .btn-close {
    margin: 0;
}
.modal-header .btn-close {
    margin: 0;
}

/* table .table-hover .table-light a {
    text-decoration: none;
    color: inherit;
    display: block; 
} */
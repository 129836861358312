* {
  padding: 0;
  margin: 0;
}

h1 {
  margin: 5%;
  text-align: center;
  font-size: 60px;
}

.ButtonBNB {
  background-color: #ea004b;
  border: none;
  color: white;
  border-radius: 7px;
  width: 100%;
  height: 40px;
  bottom: 0;
}

.ButtonBNB:hover {
  background-color: #d30043;
  border: none;
  width: 100%;
  height: 40px;
}

.ButtonBNB:active {
  background-color: #f1004c;
  border: none
}

.ButtonBNB:disabled {
  background-color: #d3d3d3;
  color: white;
  cursor: not-allowed;
  border: 1px solid #d3d3d3;
}

a {
  color: initial;
  text-decoration: none;
}

select {
  width: 24%;
  text-align: center;
  margin: 2%;
  padding: 1%;
  font-size: 30px;
  border-radius: 20px;
}

textarea {
  font-size: 30px;
  margin: 1%;
  padding-right: 1%;
  padding-left: 1%;
  width: 60%;
}

table {
  font-size: 30px;
}

.active-pagination-link {
  background-color: #ea004b;
  color: white;
  border-radius: 50%;
}
.btn-primary {
    color: white;
}


@media screen and (min-width: 0px) and (max-width: 500px) {
    .card {
        max-width: 150px;
    }

    .NavDisktop {
        display: none !important;
    }

    .StyleMobail {
        width: 100%;
    }

    #PagePayCard {
        width: 95%;
    }

    .search-bar {
        top: 40px;
    }
    #PageAdmin{
        width: 94%;
    }
}
#liUserId {
    margin: 0.5%;
    width: 100%;
    display: flex;
    align-items: baseline;
}

#liUserId div {
    font-size: 20px;
    font-weight: 500;
}

.custom-pagination {
    list-style-type: none;
}

.pagination-tag-anchor {
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.pagination-tag-anchor:hover {
    background-color: rgb(218, 218, 218);
}

.StyleMobail {
    width: 85%;
}

.liUserDetalse {
    width: 500px;
}

@media screen and (max-width:500px) {
    #Page {
        width: 95%;
        margin: 0 2.5%;
    }

    .liUserDetalse {
        width: 200px;
    }

    .carousel-item {
        margin-top: 10px;
    }
}